import { FirestoreTimestamp } from '#/Utils/types';
import { PaginationQueryParams } from './pagination.types';
import {
  AirtablePayload,
  AccessPermissionsType,
  EmailConfigsType,
  FileTypeConfigOverridesType,
  FileTypeConfigsPayload,
  SLAConfigsType,
  NotificationConfig,
  PsvConfigs,
} from './organization.types';
import { ReferenceLinksConfig } from '../components/OrganizationSettings/ConfigurationsTab/ReferenceLinksSection';

export const UserRole = {
  CREDENTIALING_USER: 'Credentialing User',
  PLATFORM_USER: 'Platform User',
  ORGANIZATION_ADMIN: 'Organization Admin',
  BUSINESS_ADMIN: 'Business Admin',
  ANALYST: 'Analyst',
  API_USER: 'API User',
  PROVISIONAL_APPROVER: 'Provisional Approver',
  ENTITY_MANAGER: 'Entity Manager',
  LICENSING_TEAM_LEAD: 'Licensing Team Lead',
  LICENSING_ADMIN: 'Licensing Admin',
  LICENSING_ANALYST: 'Licensing Analyst',
  SUPERVISOR: 'Supervisor',
  LICENSING_SPECIALIST: 'Licensing Specialist',
  ROSTER_MANAGER: 'Roster Manager',
  MEDICAL_DIRECTOR: 'Medical Director',
  PE_LEAD_ROLE: 'PE Lead',
  PE_ANALYST_ROLE: 'PE Analyst',
  PE_CLIENT_ADMIN: 'PE Client Admin',
  VIEWER_ROLE: 'Viewer',
} as const;

export type UserRoleValue = (typeof UserRole)[keyof typeof UserRole];

export interface PipelineFetchConfigs {
  autoGenerateNpdbPdf?: boolean;
  oigSanctionEnabled?: boolean;
  gsaSamSanctionEnabled?: boolean;
  mooSanctionEnabled?: boolean;
  ofacSanctionEnabled?: boolean;
  stateExclusionsListSanctionEnabled?: boolean;
  caqhEducationTrainingProfessional?: boolean;
  caqhEducationTrainingResidency?: boolean;
  caqhEducationTrainingFellowship?: boolean;
  caqhAdmittingPrivileges?: boolean;
  forceFetchSecondarySourceLicensesEnabled?: boolean;
  forceUpdateAssignStatesFromCAQH?: boolean;
  npdbEnrollmentEnabledForOrganization?: boolean;
}

export interface DataSourceConfiguration {
  preclusionsListTableName?: string;
  preclusionsListSanctionedProviderOverride?: boolean;
  pipelineFetchConfigs?: PipelineFetchConfigs;
}

export interface Membership {
  organizationId: string;
  createdByName: string | null;
  createdAt: FirestoreTimestamp;
  createdBy: string;
  userId: string;
  id: string;
  organizationName: string;
  npdbEnrollmentEnabled?: boolean;
  caqhEnabled?: boolean;
  airtable?: AirtablePayload;
  fileTypeConfigs?: FileTypeConfigsPayload;
  facilityFileTypeConfigs?: FileTypeConfigsPayload;
  slaConfigs?: SLAConfigsType;
  accessPermissions?: AccessPermissionsType;
  emailConfigs?: EmailConfigsType;
  referenceLinksConfig?: ReferenceLinksConfig;
  defaultFileTypeConfigOverrides?: FileTypeConfigOverridesType;
  notificationConfig: NotificationConfig;
  dataSourceConfiguration?: DataSourceConfiguration;
  psvConfigs?: PsvConfigs;
}

export interface UserDetails {
  organizationId: string;
  createdAt: FirestoreTimestamp;
  lastName: string;
  firstName: string;
  createdBy: string;
  email: string;
  status: string;
  id: string;
  updatedBy: string;
  memberships: Membership[];
}

export interface FetchUserApiResponse {
  roles: Array<UserRoleValue>;
  toReturn: UserDetails;
}

export interface FetchUsersApiPayload extends PaginationQueryParams {
  q?: string;
}

export interface FetchUsersApiResponse {
  users: Array<Partial<UserDetails>>;
  count: number;
}

export interface UpdateUserByIdPayload {
  firstName?: string;
  lastName?: string;
  receiveNotifications?: boolean;
}

export interface CreateUserRequestDto {
  email: string;
  firstName: string;
  lastName: string;
  roleIds: string[];
  organizationIds?: string[];
}
