import { CredentialingWorkflowNote } from '#/api/credentialing';
import { CredentialingCycle } from '#/Utils/Constants';
import { ApiResponseDateTime, FirestoreTimestamp } from '#/Utils/types';
import { apiResponseDateToDate } from '../Utils/date';
import { PEWorkflowOutreachCorrespondences } from './provider-enrollment-workflows.types';
import { PsvRequirementSections } from '#/Utils/Constants';

export interface WorkflowNote {
  id: string;
  message: string;
  createdByName: string;
  createdAt?: Date;
  updatedAt?: Date;
  createdBy: string;
}

export interface CredWorkflowStep {
  type: 'credWorkflowStep';
  createdAt: ApiResponseDateTime;
  id: string;
  performer: string;
  description: string;
  createdBy: string;
  seqNo: number;
  enteredAt: ApiResponseDateTime;
  exitedAt: ApiResponseDateTime;
  status: string;
  title: string;
  stepId: string;
  credCommDetails: any;
}

export interface CredTimelineStep {
  id: string;
  name: string;
  seqNo?: number;
  status: 'in-progress' | 'completed' | 'pending';
  createdAt?: ReturnType<typeof apiResponseDateToDate>;
  enteredAt?: ReturnType<typeof apiResponseDateToDate>;
  exitedAt?: ReturnType<typeof apiResponseDateToDate>;
}

export interface PsvTask {
  area?: string;
  description?: string;
  status?: string;
  section?: keyof typeof PsvRequirementSections;
  sectionDescription?: string;
  taskId?: string;
}

export interface PsvChecklist {
  orgName?: string;
  providerid?: string;
  providerType?: string;
  tasks?: Array<PsvTask>;
}

export interface RulesEngineResults {
  attestationDate?: RulesEngineAttestationDateCheckResults;
  missingStateLicense?: RulesEngineMissingStateLicenseCheckResults;
  missingDeaLicense?: RulesEngineMissingDeaLicenseCheckResults;
  missingBoardCertificationSupportingDoc: RulesEngineMissingBoardCertificationSupportingDocResults;
  incompleteNpdb?: RulesEngineIncompleteNpdbCheckResults;
  missingPsvSupportingDoc?: RulesEngineMissingPsvSupportingDocumentCheckResults;
  missingCdsLicense?: RulesEngineMissingCdsLicenseCheckResults;
  validateMalpracticeOccurrenceCoverage?: RulesEngineValidateMalpracticeOccurrenceCoverageAmountResponseResults;
  missingSanction?: RulesEngineMissingSanctionCheckResults;
  sanctionSourceMismatch?: RulesEngineSanctionSourceMismatchCheckResults;
  deaLicenseSourceMismatch?: RulesEngineDeaLicenseSourceMismatchCheckResults;
  multipleRuleExecutionResult?: RuleEngineMultipleRuleExecutionResults;
}

export interface RulesEngineAttestationDateCheckResults {
  orgName: string;
  rulename: string;
  valid: boolean;
}

export interface RulesEngineMissingStateLicenseCheckResults {
  rulename: string;
  valid: boolean;
  missingStates: string[];
}

export interface RulesEngineMissingDeaLicenseCheckResults {
  rulename: string;
  valid: boolean;
  missingStates: string[];
}

export interface RulesEngineMissingBoardCertificationSupportingDocResults {
  rulename: string;
  valid: boolean;
}

export interface RulesEngineIncompleteNpdbCheckResults {
  rulename: string;
  valid: boolean;
}

export interface RulesEngineMissingPsvSupportingDocumentCheckResults {
  rulename: string;
  valid: boolean;
}

export interface RulesEngineMissingCdsLicenseCheckResults {
  rulename: string;
  valid: boolean;
  missingStates: string[];
}

export interface RulesEngineValidateMalpracticeOccurrenceCoverageAmountResponseResults {
  valid: boolean;
  rulename: string;
  requiredOccurenceCoverageAmount: number;
}

export interface RulesEngineMissingSanctionCheckResults {
  rulename: string;
  valid: boolean;
  missingSanctions: string[];
}

export interface SanctionSourceMismatchResults {
  rulename: string;
  valid: boolean;
  sanctionId: string;
  sanctionSource: string;
  sanctionType: string;
}

export interface RulesEngineSanctionSourceMismatchCheckResults {
  sanctionSourceMismatchResults: SanctionSourceMismatchResults[];
  valid: boolean;
}

export interface DeaLicenseSourceMismatchResults {
  rulename: string;
  valid: boolean;
  licenseNumber: string;
}

export interface RulesEngineDeaLicenseSourceMismatchCheckResults {
  deaLicenseSourceMismatchResults: DeaLicenseSourceMismatchResults[];
  valid: boolean;
}

export interface MultipleRuleExecutionResult {
  ruleId: string;
  ruleValid: boolean;
  ruleMessage: string;
}

export interface DeaCdsData {
  deaNumber: string;
  type: string;
}

export interface StateLicenseData {
  licenseNumber: string;
  source: string;
}

export interface CdsLicenseSourceMismatchResult {
  invalidDeaCdsData: DeaCdsData[];
}

export interface StateLicenseSourceCheckResult {
  invalidStateLicenseData: StateLicenseData[];
}

export interface CaqhAuthorizationSupportingDocsCheckResult {
  missingSupportingDocumentTypes: string[];
}

export interface MissingLicenses {
  missingLicences: string[];
}

export interface MissingVerifiedThroughBoardCertificationSourceInEducationAndTrainingResult {
  missingEducationAndTrainingDataSources: string[];
}

export interface ExcludeSanctionResult {
  presentSanctionsToBeExcluded: string[];
}

export type RuleExecutionResult = MultipleRuleExecutionResult &
  CdsLicenseSourceMismatchResult &
  StateLicenseSourceCheckResult &
  CaqhAuthorizationSupportingDocsCheckResult &
  MissingLicenses &
  MissingVerifiedThroughBoardCertificationSourceInEducationAndTrainingResult &
  ExcludeSanctionResult;

export interface RuleEngineMultipleRuleExecutionResults {
  [key: string]: RuleExecutionResult[];
}

export interface CredentialingWorkflowDetails {
  workflowId: string;
  providerId: string;
  organizationId: string;
  version: number;
  isCompleted: boolean;
  onStep: CredTimelineStep;
  psvFile?: string;
  psvFileSignedUrl?: string;
  psvChecklist?: PsvChecklist;
  rulesEngineResults?: RulesEngineResults;
  credentialingCycle?:
    | typeof CredentialingCycle.INITIAL
    | typeof CredentialingCycle.RECRED;
  outreachReasons?: string[];
  steps: CredTimelineStep[];
  notes?: WorkflowNote[];
  credCommDetails: any;
  totalOutreachAttempts?: number;
  lastOutreachAttempt?: FirestoreTimestamp;
  assignment?: Assignment;
  psvFileGenerationErrors?: string[];
  psvGenerated: boolean;
}

export interface Assignment {
  assignedToFirstName: string;
  assignedToLastName: string;
  assignedToId: string;
  assignedByFirstName: string;
  assignedByLastName: string;
  assignedById: string;
  assignedOn: FirestoreTimestamp;
  lastActivity: FirestoreTimestamp;
}
export interface FetchCredentialingWorkflowByIdResponse {
  credCommDetails: any;
  credentialingCycle?:
    | typeof CredentialingCycle.INITIAL
    | typeof CredentialingCycle.RECRED;
  id: string;
  onStep: CredWorkflowStep;
  organizationId: string;
  name: string;
  providerId: string;
  version: number;
  psvFile?: string;
  psvFileSignedUrl?: string;
  psvGenerated: boolean;
  psvChecklist?: PsvChecklist;
  rulesEngineResults?: RulesEngineResults;
  outreachReasons?: string[];
  createdAt: ApiResponseDateTime;
  templateId: string;
  createdBy: string;
  isCompleted?: boolean;
  steps: CredWorkflowStep[];
  notes?: CredentialingWorkflowNote[];
  totalOutreachAttempts?: number;
  lastOutreachAttempt?: FirestoreTimestamp;
  assignment?: Assignment;
  psvFileGenerationErrors?: string[];
}

export interface CredOutreachCorrespondence {
  to?: string[];
  from?: string;
  note?: string;
  isNotePrivate?: boolean;
  body?: string;
  reason?: string[];
  subject?: string;
  createdAt: FirestoreTimestamp;
  createdByName?: string;
  updatedBy: string;
  updatedByName?: string;
  method: OutreachMethodsType;
  phone: string;
  createdBy: string;
  outcome: string;
  updatedAt: FirestoreTimestamp;
  id: string;
  sgMessageId?: string;
  rootMessageId?: string;
  replies?: PEWorkflowOutreachCorrespondences[];
  attachmentFiles?: Attachment[];
}

export interface CredOutreachCorrespondenceOverview {
  method: string;
  createdAt: FirestoreTimestamp;
  updatedBy: string;
  outcome: string;
  sgMessageId?: string;
  rootMessageId?: string;
  replyCount?: number;
}
export interface OutreachCorrespondenceList {
  correspondences: CredOutreachCorrespondence[];
  overview: CredOutreachCorrespondenceOverview[];
}

export interface AnalystData {
  firstName: string;
  lastName: string;
  id: string;
  currentlyAssigned: number;
}

export const OutreachMethods = {
  Email: 'email',
  Phone: 'phone',
  Note: 'note',
  Other: 'other',
  EmailToAnalyst: 'emailToAnalyst',
} as const;

export type OutreachMethodsType =
  (typeof OutreachMethods)[keyof typeof OutreachMethods];

export interface AttachmentData {
  content: string;
  filename: string;
  type?: string;
  disposition?: string;
  contentId?: string;
}

export interface Attachment extends File {
  filename?: string;
  attachmentUrl?: string;
}

export interface OutreachAttemptPayload {
  method: OutreachMethodsType;
  reason?: string[];
  note?: string;
  to?: string[];
  phone?: string;
  subject?: string;
  outcome?: string;
  body?: string;
  id?: string;
  isEmailSaveOnly?: boolean;
  attachments?: File[];
  isNotePrivate?: boolean;
}

export interface OutreachAttempMarkAsReadPayload {
  workflowId: string;
  outreachId: string;
}

export interface EditOutreachAttemptPayload {
  note?: string;
  outcome?: string;
}

export type CredentialingStatusType =
  | 'Not sent to credentialing'
  | 'In Progress'
  | 'Data missing'
  | 'Outreach in progress'
  | 'PSV complete by CertifyOS'
  | 'Credentialing restarted'
  | 'PSV ready'
  | 'Cred approved'
  | 'Hold for Cred Comm'
  | 'Cred denied'
  | 'Provider Terminated'
  | 'Recredentialing in progress'
  | 'Tabled'
  | 'Withdrawn/Cancelled';

export interface CredentialingTimelineFromApi {
  medicalDirectorName: string;
  receivedForCredentialingDate?: ApiResponseDateTime;
  attestationDate?: ApiResponseDateTime;
  lastCredentialedDate?: ApiResponseDateTime;
  psvCompleteDate?: ApiResponseDateTime;
  credentialingDecisionDate?: ApiResponseDateTime;
  nextCredentialingDate?: ApiResponseDateTime;
  verificationDate?: ApiResponseDateTime;
  verifierId?: string;
  id?: string;
  createdAt?: ApiResponseDateTime;
  createdBy?: string;
  createdByName?: string;
  updatedAt?: ApiResponseDateTime;
  updatedBy?: string;
  updatedByName?: string;
  initialCredentialingDate?: ApiResponseDateTime;
}

export interface CredentialingTimeline {
  medicalDirectorName?: string;
  receivedForCredentialingDate?: Date;
  attestationDate?: Date;
  lastCredentialedDate?: Date;
  psvCompleteDate?: Date;
  credentialingDecisionDate?: Date;
  nextCredentialingDate?: Date;
  verificationDate?: Date;
  verifierId?: string;
  id?: string;
  createdAt?: Date;
  createdBy?: string;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: string;
  updatedByName?: string;
  initialCredentialingDate?: Date;
}

export interface UpdateCredentialingWorkflowTimeline {
  attestationDate: Date;
}

export interface UpdateCredentialingWorkflow {
  credentialingCycle: string;
}

export interface CredentialingWorkflow {
  id: string;
  assignment: Assignment;
  assignedStates: string[];
  providerId?: string;
  organizationId: string;
  firstName: string;
  lastName: string;
  npi: string;
  providerType: string;
  providerFileType: string;
  onStep: string;
  createdAt: Date;
  hasFlags?: boolean;
  outreachReasons?: string[];
  credentialingCycle?: string;
}

export interface CredentialingWorkflowListState {
  data: CredentialingWorkflow[];
  count?: number;
  loading?: boolean;
  updating?: boolean;
  creating?: boolean;
  approveIds?: string[];
  errorMessage?: string;
  creatingLicensing?: boolean;
}

export interface OutreachCorrespondenceItem {
  id: string;
  note: string;
  updatedBy?: string;
  updatedAt?: string;
}
