import { ApiResponseDateTime, FirestoreTimestamp } from '#/Utils/types';
import { EnumerationType } from './providers.types';

export type FacilityProvider = {
  id: string;
  npi: string;
  name: string;
  type: string;
};

export type FacilitySupportingDocument = {
  createdAt: Date | null;
  createdBy: string;
  fileType: string;
  fileUrl: string;
  gsUrl: string;
  id: string;
  originalFilename: string;
  subCollectionReference: string;
};

export type BusinessLicense = {
  id: string;
  name: string;
  entity: string;
  city: string;
  type: string;
  incorporationDate: string;
};

export type TinData = {
  id: string;
  tin: string;
};

export type NpiData = {
  id: string;
  npi: string;
};

export type TimelineItem = {
  id: string;
  enteredAt: ApiResponseDateTime;
  createdBy: string;
  createdAt: ApiResponseDateTime;
  title: string;
};

export type Insurance = {
  policyNumber: string;
  source: string;
  insuranceType: string;
  expirationDate: Date;
};

export type StateLicense = {
  licenseType: string;
  source: string;
  licenseNumber: string;
  status: string;
  issueDate: Date;
  expirationDate: Date;
};

export type Accreditation = {
  accreditationBody: string;
  applicable: string;
  effectiveDate: Date;
  expirationDate: Date;
  status: string;
};

export type Sanction = {
  sanctionType: string;
  source: string;
  reportResults: string;
};

export type Npdb = {
  applicable: string;
  source: string;
  reportResults: string;
};

export type Certification = {
  source: string;
  applicable: string;
  number: number;
  issueDate: Date;
  expirationDate: Date;
};

export interface FetchFacilityByIdResponse {
  id: string;
  name: string;
  dba?: string;
  npi?: string;
  address: string;
  city: string;
  facilityId: string;
  organizationId: string;
  zipcode: string;
  credentialingStatus: string;
  credentialingStartDate?: FirestoreTimestamp;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  contactFax: string;
  state: string;
  tins?: TinData[];
  type?: string;
  npis?: NpiData[];
  liabilityInsurances?: Insurance[];
  stateLicenses?: StateLicense[];
  providers?: FacilityProvider[];
  accreditations?: Accreditation[];
  sanctions?: Sanction[];
  npdb?: Npdb[];
  certifications?: Certification[];
  businessLicense?: BusinessLicense[];
  documents?: FacilitySupportingDocument[];
  timelines?: TimelineItem[];
  fileTypeScores?: FacilityFileTypeScore;
  createdAt: ApiResponseDateTime;
  credentialingWorkflowTimeline: any;
  nppes?: FacilityNppesData[];
}

export type ProcessedTimelineItem = {
  id: string;
  name: string;
  status: 'in-progress' | 'completed' | 'pending';
  enteredAt?: Date;
  createdBy: string;
};

export interface FacilityDetails {
  id: string;
  npi?: string;
  name: string;
  dba?: string;
  type?: string;
  status: string;
  facilityId: string;
  facilitySupportingDocuments?: FacilitySupportingDocument[];
  sections: {
    data: any;
    config: any;
  }[];
  timelines?: TimelineItem[];
  workflow?: {
    data: any;
    config: any;
  }[];
  fileTypeScores?: FacilityFileTypeScore;
  nppes?: FacilityNppesData;
}

export type FacilityFileTypeScore = {
  totalScore: number;
  licensesValues: {
    scores: {
      expiredLicensesFoundCount: number;
      licenseMissing: number;
    };
    stats: { expiredLicensesFoundCount: number; isLicenseMissing: number };
    totalScore: number;
  };
  npdbValues: {
    scores: {
      npdbAdverseActionsCount: number;
    };
    stats: {
      npdbAdverseActionsFoundCount: number;
    };
    totalScore: number;
  };
  sanctionValues: {
    scores: {
      sanctionsMatchFoundCount: number;
    };
    stats: { sanctionsMatchFoundCount: number };
    totalScore: number;
  };
};
export type UpdateFacilityPayload = {
  facilityId: string;
  body: Partial<FetchFacilityByIdResponse>;
};

export type UpdateFacilityStatusPayload = {
  facilityId: string;
  body: {
    status: string;
  };
};
export interface AddNewFacilityPayload {
  name: string;
  address: string;
  city: string;
  zipcode: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
  contactFax: string;
  state: string;
}

export interface DocUpload {
  file: File;
  fileType: string;
}

export type BulkMoveErrorResponseItem = {
  reason: { message: string };
  id: string;
  status: string;
};

export interface FetchFacilityListResponse {
  data: FacilityDetails[];
  count: number;
}

export interface FacilityNppesData {
  id?: string;
  organizationName?: string;
  organizationalSubpart?: string;
  firstName?: string;
  lastName?: string;
  telephoneNumber?: string;
  title?: string;
  certificationDate?: Date;
  enumerationDate?: Date;
  lastUpdatedAt?: Date;
  status?: string;
  enumerationType?: EnumerationType;
  nppesId?: string;
  NppesNumber?: string;

  identifiers?: unknown[];
  otherNames?: FacilityNppesFacilityName[];
  taxonomies?: FacilityNppesTaxonomy[];
  addresses?: NppesAddress[];
  active?: boolean;
  valid?: boolean;
  createdAt?: Date;
  createdBy?: string;
  createdByName?: string;
  updatedAt?: Date;
  updatedBy?: string;
  verifiedAt?: Date;
  verifiedByFullName?: string;
  validationErrors?: string[] | null;
}

export interface FacilityNppesFacilityName {
  organizationName?: string;
  type?: string;
  code?: string;
}

export interface FacilityNppesTaxonomy {
  code?: string;
  taxonomyGroup?: string;
  desc?: string;
  state?: string;
  license?: string;
  primary?: boolean;
}

export interface NppesAddress {
  countryCode?: string;
  countryName?: string;
  addressType?: string;
  postalCode?: string;
  faxNumber?: string;
  address1?: string;
  state?: string;
  city?: string;
  telephoneNumber?: string;
  addressPurpose?: string;
}

export const NppesValidationErrors = {
  NameMismatch: 'NAME_MISMATCH',
  wrongNpiType: 'WRONG_NPI_TYPE',
  noDataFound: 'NO_DATA_FOUND',
  requestFailed: 'REQUEST_FAILED',
};
