import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import {
  FETCH_LOGGEDIN_USER_FAILURE,
  FETCH_LOGGEDIN_USER_LOADING,
  FETCH_LOGGEDIN_USER_SUCCESS,
} from '../actionTypes';
import { usersApiV2 } from '#/services';
import { apiResponseDateToDate } from '#/Utils/date';

export const fetchLoggedInUser = () => {
  return async (dispatch: Dispatch): Promise<any> => {
    dispatch({
      type: FETCH_LOGGEDIN_USER_LOADING,
    });

    const data = dispatch(
      usersApiV2.endpoints.fetchUserById.initiate('me', {
        forceRefetch: true,
      }) as unknown as AnyAction,
    );

    try {
      const response = await data.unwrap();
      let payload = { ...response.toReturn };
      payload.createdAt = payload?.createdAt?._seconds;
      payload.createdAt = Date.parse(
        (
          apiResponseDateToDate(payload?.createdAt) || new Date()
        ).toDateString(),
      );
      payload.roles = response.roles;
      return dispatch({
        type: FETCH_LOGGEDIN_USER_SUCCESS,
        payload,
      });
    } catch (error: any) {
      return dispatch({
        type: FETCH_LOGGEDIN_USER_FAILURE,
        payload: { error },
      });
    }
  };
};

export const updateUserMembership = (userData: any, updatePayload: any) => {
  return async (dispatch: Dispatch): Promise<any> => {
    const memberships = userData.data.memberships.map((membership: any) => ({
      ...membership,
      ...(membership.organizationId === userData.selectedOrgId
        ? updatePayload
        : {}),
    }));

    dispatch({
      type: FETCH_LOGGEDIN_USER_SUCCESS,
      payload: { ...userData.data, memberships },
    });
  };
};
