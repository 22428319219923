import { isEmpty } from 'lodash';
import {
  FETCH_LOGGEDIN_USER_FAILURE,
  FETCH_LOGGEDIN_USER_LOADING,
  FETCH_LOGGEDIN_USER_SUCCESS,
  UPDATE_LOGGEDIN_USER_FAILURE,
  UPDATE_LOGGEDIN_USER_LOADING,
  UPDATE_LOGGEDIN_USER_SUCCESS,
  UPDATE_SELECTED_ORG,
} from '../actionTypes';
import { Membership } from '#/types/user.types';
import { allOrgsValue } from '../../Utils/Constants';

export interface LoggedInUser {
  status: string;
  lastName: string;
  id: string;
  receiveNotifications: boolean;
  firstName: string;
  createdAt: number;
  email: string;
  roles: string[];
  organizationId: string;
  memberships: Membership[];
}

export interface LoggedInUserState {
  data?: LoggedInUser | null;
  selectedOrgId?: string;
  loading?: boolean;
  updating?: boolean;
  errorMessage?: string;
}

const initialState: LoggedInUserState = {
  data: null,
  loading: false,
  updating: false,
  errorMessage: undefined,
};

export const loggedInUserReducer = (
  state: LoggedInUserState = initialState,
  action: { type: string; payload: any },
) => {
  switch (action.type) {
    case FETCH_LOGGEDIN_USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case FETCH_LOGGEDIN_USER_SUCCESS:
      let selectedOrgId = state.selectedOrgId;
      if (
        selectedOrgId !== allOrgsValue &&
        !isEmpty(action?.payload?.memberships)
      ) {
        const homeOrg = action.payload.memberships.find(
          (org: Membership) =>
            org.organizationId ===
            (selectedOrgId || action.payload?.organizationId),
        );
        selectedOrgId =
          homeOrg?.organizationId ||
          action.payload.memberships[0].organizationId;
      }

      return {
        ...state,
        selectedOrgId,
        data: action.payload,
        loading: false,
      };
    case UPDATE_LOGGEDIN_USER_LOADING:
      return {
        ...state,
        updating: true,
      };
    case UPDATE_LOGGEDIN_USER_FAILURE:
      return {
        ...state,
        updating: false,
      };
    case UPDATE_LOGGEDIN_USER_SUCCESS:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
        loading: false,
        updating: false,
      };
    case FETCH_LOGGEDIN_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.error.message,
      };
    case UPDATE_SELECTED_ORG:
      return {
        ...state,
        selectedOrgId: action.payload.orgId,
      };
    default:
      return state;
  }
};
